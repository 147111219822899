import React from "react";
import * as PropTypes from "prop-types";

class ForecastLoanResults extends React.Component {

  static propTypes = {
    results: PropTypes.object.isRequired,
    errors: PropTypes.array.isRequired,
    line_item_headings: PropTypes.object.isRequired
  };

  renderResults = (results, labels) => {

    const forecast = Object.entries(results).map(([key, value]) => {
      return (
        <tr key={key}>
          <td>{labels[key]}</td>
          <td>{value}</td>
        </tr>
      );
    });

    if (Object.keys(results).length) {
      return (
        <table className="table table--mathematical">
          <tbody>
          {forecast}
          </tbody>
        </table>
      );
    }
  };

  renderErrors = (errors) => {
    if (errors.length) {
      const content = errors.map((error) => {
        return <p className="error">{error}</p>
      });
      return <div>{content}</div>;
    }
  };

  render() {
    return (
      <div>
        {this.renderErrors(this.props.errors)}
        {this.renderResults(this.props.results, this.props.line_item_headings)}
      </div>
    );
  }
}

export default ForecastLoanResults;
