import * as PropTypes from "prop-types";
import React from "react";

class EarlyRepaymentResults extends React.Component {
  static propTypes = {
    results: PropTypes.object.isRequired,
    errors: PropTypes.array.isRequired,
    line_item_headings: PropTypes.object.isRequired
  };

  renderResults = (results, labels) => {
    if (Object.keys(results).length) {
      const loan = Object.entries(results["loan_rates"]).map(([key, value]) => {
        return (
          <tr key={key}>
            <td>{labels[key]}</td>
            <td>{value}</td>
          </tr>
        );
      });
      const otherRates = Object.entries(results["other_rates"]).map(([key, value]) => {
        return (
          <tr key={key}>
            <td>{labels[key]}</td>
            <td>{value}</td>
          </tr>
        );
      });
      return (
        <React.Fragment>
          <table className="table table--mathematical">
            <tbody>
              {loan}
            </tbody>
          </table>
          <table className="table table--mathematical">
            <tbody>
              {otherRates}
            </tbody>
          </table>
        </React.Fragment>
      );
    }
  };

  renderErrors = (errors) => {
    if (errors.length) {
      const content = errors.map((error) => {
        return <p className="error">{error}</p>
      });
      return <div>{content}</div>;
    }
  };

  render() {
    return (
      <div>
        {this.renderErrors(this.props.errors)}
        {this.renderResults(this.props.results, this.props.line_item_headings)}
      </div>
    );
  }
}

export default EarlyRepaymentResults;