import React from "react";
import * as PropTypes from "prop-types";
import ForecastLoanResults from "./ForecastLoanResults";

class ForecastLoanCalculator extends React.Component {
  static propTypes = {
    minimum_installation_date: PropTypes.string.isRequired,
    input_labels: PropTypes.object.isRequired,
    line_item_headings: PropTypes.object.isRequired
  };

  state = {
    buttonLabel: this.props.input_labels["calculate"],
    installationDate: new Date().toISOString().substring(0, 10),
    installationCost: 0,
    eecaFunding: 0,
    errors: [],
    results: {},
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log("handleSubmit");
    return $.ajax({
      url: `/api/forecast_loan`,
      type: "POST",
      data: {
        installation_date: this.state.installationDate,
        installation_cost: this.state.installationCost,
        eeca_funding: this.state.eecaFunding
      },
      success: response => {
        this.setState({ results: response });
        this.setState({ errors: [] });
        this.setState({ buttonLabel: this.props.input_labels["recalculate"] });
      },
      error: (response) => {
        this.setState({ results: {} });
        this.setState({ errors: response.responseJSON });
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <label htmlFor="installationCost">{this.props.input_labels["total_installation_cost"]}</label>
          <input type="number" name="installationCost" id="installationCost" min="1" step="0.01"
                 onChange={this.handleInputChange} required/>

          <label htmlFor="eecaFunding">{this.props.input_labels["eeca_funding"]}</label>
          <input type="number" name="eecaFunding" id="eecaFunding" defaultValue="0" min="0" step="0.01"
                 onChange={this.handleInputChange} required/>

          <label htmlFor="installationDate">{this.props.input_labels["installation_date"]}</label>
          <input type="date" name="installationDate" id="installationDate" min={this.props.minimum_installation_date}
                 defaultValue={this.state.installationDate}  onChange={this.handleInputChange} required/>
          <input type="submit" className="button button--full-width" value={this.state.buttonLabel}/>
        </form>
        <ForecastLoanResults line_item_headings={this.props.line_item_headings} errors={this.state.errors} results={this.state.results} />
      </React.Fragment>
    );
  }
}

export default ForecastLoanCalculator;
