import * as PropTypes from "prop-types";
import React from "react";
import EarlyRepaymentResults from "./EarlyRepaymentResults"

class EarlyRepaymentCalculator extends React.Component {
  static propTypes = {
    loan_id: PropTypes.number.isRequired,
    minimum_payment_date: PropTypes.string.isRequired,
    maximum_payment_date: PropTypes.string.isRequired,
    settlement_options: PropTypes.array.isRequired,
    input_labels: PropTypes.object.isRequired,
    line_item_headings: PropTypes.object.isRequired
  };

  state = {
    buttonLabel: this.props.input_labels["calculate"],
    loanId: this.props.loan_id,
    paymentDate: new Date().toISOString().substring(0, 10),
    settlementPeriodNumber: this.props.settlement_options[0].value,
    ratesAmountDue: 1,
    ratesAmountPaid: 0,
    errors: [],
    results: {},
  };

  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    return $.ajax({
      url: `/api/early_repayment/${this.state.loanId}`,
      type: "POST",
      data: {
        payment_date: this.state.paymentDate,
        settlement_period_number: this.state.settlementPeriodNumber,
        rates_due: this.state.ratesAmountDue,
        rates_paid: this.state.ratesAmountPaid
      },
      success: response => {
        this.setState({ results: response });
        this.setState({ errors: [] });
        this.setState({ buttonLabel: this.props.input_labels["recalculate"] });
      },
      error: (response) => {
        this.setState({ results: {} });
        this.setState({ errors: response.responseJSON });
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <label id="paymentDateLabel" htmlFor="paymentDate">{this.props.input_labels["payment_date"]}</label>
          <input type="date" name="paymentDate" aria-labelledby="paymentDateLabel" defaultValue={this.state.paymentDate} min={this.props.minimum_payment_date} max={this.props.maximum_payment_date} onChange={this.handleInputChange}></input>
          <label id="settlementPeriodNumberLabel" htmlFor="settlementPeriodNumber">{this.props.input_labels["settlement_period"]}
            <select id="settlementPeriodNumber" name="settlementPeriodNumber" aria-labelledby="settlementPeriodNumberLabel" defaultValue={this.state.settlementPeriodNumber} onChange={this.handleInputChange}>
              {this.props.settlement_options.map((option) => {
                return <option key={option.value} value={option.value}>{option.display}</option>;
              })}
            </select>
          </label>

          <label id="ratesAmountDueLabel" htmlFor="ratesAmountDue">{this.props.input_labels["rates_due"]}</label>
          <input type="number" name="ratesAmountDue" id="ratesAmountDue" aria-labelledby="ratesAmountDueLabel" min="1" step="0.01" defaultValue={this.state.ratesAmountDue} onChange={this.handleInputChange}></input>

          <label id="ratesAmountPaidLabel" htmlFor="ratesAmountPaid">{this.props.input_labels["rates_paid"]}</label>
          <input type="number" name="ratesAmountPaid" id="ratesAmountPaid" aria-labelledby="ratesAmountPaidLabel" min="0" step="0.01" defaultValue={this.state.ratesAmountPaid} onChange={this.handleInputChange}></input>
          <input type="submit" className="button button--full-width" value={this.state.buttonLabel} />
        </form>
        <EarlyRepaymentResults line_item_headings={this.props.line_item_headings} errors={this.state.errors} results={this.state.results} />
      </React.Fragment>
    );
  }
}

export default EarlyRepaymentCalculator;